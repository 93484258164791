.custom-switch .form-check-input:checked {
	background-color: #279447 !important;
	border-color: rgb(33, 33, 60) !important;
}

.custom-switch .form-check-input {
	background-color: #a11c26 !important;
	border-color: rgb(33, 33, 60) !important;
}

.dark-blue-button {
	background-color: rgb(33, 33, 60) !important;
}

.wide-dark-blue-button {
	background-color: rgb(33, 33, 60) !important;
	width: 100% !important;
}
